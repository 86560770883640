<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="商户名称">
          <el-input style="width: 180px" clearable v-model.trim="searchData.store_title" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker
            style="width: 210px"
            v-model="searchData.created_at_range"
            size="mini"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">已通过</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
    </div>
    <el-button style="margin-bottom: 20px" icon="el-icon-download" type="primary" size="mini" plain @click="exportTable">导出</el-button>
    <el-table :data="list">
      <el-table-column prop="account_name" label="商户" min-width="220">
        <template slot-scope="{ row }">
          <div v-if="row.store">
            <div>名称：{{ row.store.store_title }}</div>
            <div>可提现金额：{{ row.store.store_balance.balance }}</div>
            <div>冻结金额（收益）：{{ row.store.store_balance.frozen_income }}</div>
            <div>冻结金额（提现）：{{ row.store.store_balance.frozen_withdraw }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bank_name" label="账户类型" min-width="150">
        <template slot-scope="{ row }">
          {{ row.account_type }} <span v-if="row.account_type == '银行卡'">（{{ row.bank_name }}）</span>
        </template>
      </el-table-column>
      <el-table-column prop="account_name" label="账户姓名" min-width="100"> </el-table-column>
      <el-table-column prop="account_number" label="账号" min-width="120"> </el-table-column>
      <el-table-column prop="account_number" label="状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="small">待审核</el-tag>
          <el-tag v-else-if="row.status == 10" type="danger" size="small">已拒绝</el-tag>
          <el-tag v-else-if="row.status == 20" type="primary" size="small">已通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="提现金额" min-width="110"> </el-table-column>
      <el-table-column prop="remark" label="备注" min-width="150"> </el-table-column>
      <el-table-column prop="created_at" label="申请时间" min-width="150"> </el-table-column>
      <el-table-column prop="id" label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" v-if="row.status == 0" type="primary" :underline="false" @click="review(row)">审核</el-link>
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 审核 -->
    <el-dialog title="审核" :visible.sync="show_status" width="width">
      <el-form ref="status" :model="statusData" label-width="120px">
        <el-form-item label="状态">
          <el-radio-group v-model="statusData.status">
            <el-radio :label="20">审核通过</el-radio>
            <el-radio :label="10">审核拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input style="width: 300px" type="textarea" v-model="statusData.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_status = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="confirmSatatus" size="mini">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, reviewAPI, delAPI, DownloadAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],

  data() {
    return {
      show_status: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        account_name: '',
        account_number: '',
        status: -1,
        created_at_range: '',
        store_title: ''
      },
      total: 0,
      statusData: {
        id: '',
        status: 20,
        remark: ''
      }
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { page, page_size, status, created_at_range, account_name, account_number, store_title } = this.searchData
      let params = {}
      if (status == -1) {
        params = {
          page,
          page_size,
          created_at_range,
          account_name,
          account_number,
          store_title
        }
      } else {
        params = {
          page,
          page_size,
          status,
          created_at_range,
          account_name,
          account_number,
          store_title
        }
      }

      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    exportTable() {
      const { status, created_at_range, account_name, account_number, store_title } = this.searchData
      let params = {}
      if (status == -1) {
        params = {
          created_at_range,
          account_name,
          account_number,
          store_title
        }
      } else {
        params = {
          status,
          created_at_range,
          account_name,
          account_number,
          store_title
        }
      }
      DownloadAPI(params)
    },
    // 审核按钮
    review({ id }) {
      this.statusData = { id, status: 20, remark: '' }
      this.show_status = true
    },
    // 提交审核
    confirmSatatus() {
      reviewAPI(this.statusData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_status = false
      })
    },
    // 点击tab
    handleClickTab(i) {
      if (this.searchData.status === i) return
      this.searchData.status = i
      this.getList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleDel({ id }) {
      this.$confirm('确认删除该条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
